<template>
  <div class="container">
    <div class="row d-flex justify-content-around align-items-center">
      <div class="col-3 text-left">
        <img class="w-75" src="/assets/images/logo.svg" />
      </div>
      <div class="col-6">
        <h2 class="header-title mb-0 mt-3 text-center text-uppercase">Comprobante de Gasto</h2>
      </div>
      <div class="col-3 text-right">
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="row mt-5">
          <div class="col-12">
            <h4>Proveedor:</h4>
          </div>
          <div class="col-12">
            <h3>
              {{ bill.provider.name | uppercase }}
            </h3>
          </div>
        </div>
        <table class="table border mt-5">
          <tbody>
            <tr class="border">
              <td class="font-weight-bold"><h5>Número de Orden</h5></td>
              <td>{{ bill.order_number }}</td>
              <th rowspan="5" class="text-center border">
                <p>Monto pagado</p>
                <h1>{{ bill.total | formatMoney }}</h1>
              </th>
            </tr>
            <tr>
              <th class="font-weight-bold"><h5>Fecha de Facturación</h5></th>
              <th>{{ bill.bill_date | moment('DD-MM-YYYY') }}</th>
            </tr>
            <tr class="border">
              <td class="font-weight-bold"><h5>Fecha de Vencimiento</h5></td>
              <td>{{ bill.due_date | moment('DD-MM-YYYY') }}</td>
            </tr>
            <tr class="border">
              <td class="font-weight-bold"><h5>Fecha de Pago</h5></td>
              <td>{{ bill.payment_date | moment('DD-MM-YYYY') }}</td>
            </tr>
            <tr class="border">
              <td class="font-weight-bold"><h5>Notas</h5></td>
              <td>{{ bill.notes }}</td>
            </tr>
          </tbody>
        </table>
        <table class="table border mt-5">
          <thead class="bg-secondary text-black">
            <tr>
              <th>#</th>
              <th>Descripción</th>
              <th>Cantidad</th>
              <th>Precio</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in bill.items" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ item.description }}</td>
              <td>{{ item.qty }}</td>
              <td>{{ item.amount |formatMoney }}</td>
              <td>{{ (item.qty * item.amount) | formatMoney }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row mt-5 d-flex justify-content-center align-items-center flex-column">
      <div class="mt-5 col-3 text-center">
        <a onclick="window.print()" href="#" class="d-print-none btn btn-info d-block">Imprimir</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'BillReceipt',
  props: ['id'],
  data () {
    return {
      bill: {}
    }
  },
  methods: {
    ...mapActions({
      fetchBill: 'Bill/fetchBill'
    })
  },
  mounted () {
    this.fetchBill({
      id: this.id
    })
      .then(response => {
        this.bill = response.data
      })
  }
}
</script>
